import { Capacitor } from '@capacitor/core';
import {
  CustomerInfo,
  LOG_LEVEL,
  MakePurchaseResult,
  Purchases,
  PurchasesPackage,
} from '@revenuecat/purchases-capacitor';

import { firebaseLogError, saveNativeSubscriptionToGroup } from './functions';
import { AnalyticsEvent, trackAnalyticsEvent } from '../services/analytics';
import { presentKnownErrorAlert } from './present-error-alert';
import { translatePlaceholder } from './translate';
import { getPlatform } from './capacitor-utils';

export async function initNativePurchases(groupId: string) {
  await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
  const apiKey =
    Capacitor.getPlatform() === 'ios'
      ? 'appl_HHnvZDeiXRTsVVPGvZSmcCqOuLb'
      : 'goog_rCKzviGTVnjVOLJvfsuWLrDlKli';
  await Purchases.configure({
    apiKey,
    appUserID: groupId,
  });
}

export async function getNativeProducts(offeringId: string) {
  const offerings = await Purchases.getOfferings();
  const offering = offerings.all[offeringId];
  console.log('offerings ', { offerings, offeringId });
  return offering?.availablePackages ?? [];
}

export async function subscribeNative(
  groupId: string,
  aPackage: PurchasesPackage
): Promise<'cant-subscribe' | 'success' | 'canceled' | 'error-saving'> {
  let makePurchaseResult: MakePurchaseResult | undefined;
  try {
    makePurchaseResult = await Purchases.purchasePackage({ aPackage });
  } catch (err) {
    trackAnalyticsEvent(AnalyticsEvent.product_purchase_cancelled);
    return 'canceled';
  }
  const canSubscribe = canApplyPurchaseToGroup({
    customerInfo: makePurchaseResult.customerInfo,
    groupId,
  });
  if (!canSubscribe) {
    trackAnalyticsEvent(AnalyticsEvent.product_purchase_cancelled);
    firebaseLogError({
      group_id: groupId,
      data: { sku: aPackage.product.identifier, error: 'cant-subscribe' },
    });
    return 'cant-subscribe';
  }
  try {
    const platform = getPlatform() as 'ios' | 'android';
    await saveNativeSubscriptionToGroup({
      group_id: groupId,
      aPackage,
      makePurchaseResult,
      platform,
    });

    trackAnalyticsEvent(AnalyticsEvent.product_purchased, {
      purchase_id: aPackage.product.identifier,
      source: 'website',
      currency: aPackage.product.currencyCode,
      price: aPackage.product.price,
    });
    return 'success';
  } catch (err) {
    console.error('error saving to firebase', err);
    firebaseLogError({
      group_id: groupId,
      data: { sku: aPackage.product.identifier, error: 'error-saving' },
    });
    return 'error-saving';
  }
}

export async function restorePurchases(groupId: string) {
  const { customerInfo } = await Purchases.restorePurchases();
  // @todo add backend call to restore purchase for subscriber
  return canApplyPurchaseToGroup({ customerInfo, groupId });
}

function canApplyPurchaseToGroup(params: {
  groupId: string;
  customerInfo: CustomerInfo;
}) {
  const { groupId, customerInfo } = params;
  if (customerInfo.originalAppUserId !== groupId) {
    console.error('cannot upgrade', { customerInfo, groupId });
    showPurchaseAlert();
    return false;
  }
  return true;
}

async function showPurchaseAlert() {
  trackAnalyticsEvent(AnalyticsEvent.restore_purchase_denied);
  return presentKnownErrorAlert({
    header: translatePlaceholder("Can't Upgrade"),
    message: translatePlaceholder(
      'This subscription is not valid for the current spending plan. Contact support if you think this is an issue'
    ),
  });
}
